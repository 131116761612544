@tailwind base;
@tailwind components;
@tailwind utilities;


input {
    padding: 10px;
    border-radius: 20px;
    margin-top:10px;
    min-width: 250px;
}

select {
    padding: 10px;
    border-radius: 20px;
    margin-top:10px;
    min-width: 250px;

}